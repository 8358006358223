(function($) {
	$.widget('blcms.form', {
		options: {
			ajax: true,

			tips: true,
			tipsposx: 'right',
			tipsposy: 'top',

			submit: null,
			success: null,
			presuccess: null,
			datahandler: null
		},

		$form: null,

		sending: false,
		action: null,

		_create: function() {
			var This=this;

			if (This.element.prop('tagName').toLowerCase()=='form') {
				This.$form=This.element;
			} else {
				This.$form=This.element.find('form');
			}

			This._getButtons().on('click', function() {
				This.action=$(this).data('action');

				if ($(this).attr('type')=='button')
					This.submit();
			});

			$(':input', This.$form).each(function() {
				var $input = $(this).closest('.input');

				$(this).focus(function() {
					$input.addClass('input--focused');
				});

				$(this).blur(function() {
					$input.removeClass('input--focused');
				});
			});

			This.$form.submit(function() {
				return This.submit();
			});

			if (This.options.tips) This._prepareErrorTips();
		},

		_prepareErrorTips: function() {
			var This=this;

			This.$form.find('.formtip').remove();

			var $msgs=this.$form.find('.input__error, .form__error');

			if ($msgs.length > 0) {
				var $msg=$msgs.first();
				var $parent=$msg.parent();
				var $input=$parent.find(':input').first();

				$parent.formTip({
					posx: This.options.tipsposx,
					posy: This.options.tipsposy,

					message: $msg.html()
				});

				$input.focus().select();
			}

			$msgs.detach();
		},

		_getButtons: function() {
			return this.$form.find('.submit input');
		},

		_getVars: function() {
			var This=this;
			var $inputs=This.$form.find('input, select, textarea');

			var $result=$();

			$inputs.each(function() {
				var $input=$(this);

				if (!$input.parentsUntil('form, .input').parent().is('.hint'))
					$result=$result.add($input);
			});

			return $result.serializeArray();
		},

		_setFormAction: function() {
			var This=this;

			var action=This.action;

			if (!action) {
				var $submit=This.$form.find('.submit input[type="submit"]');

				action=$submit.data('action');
			}

			var $action=This.$form.find('input[name="action"]');

			if ($action.length == 0 && action!=null) {
				$action=$('<input type="hidden" name="action">').prependTo(This.$form);
			}

			$action.val(action);
		},

		submit: function() {
			var This=this;

			if (This.sending)
				return false;

			This.sending=true;

			This._setFormAction();

			if (This.options.ajax) {
				This._trigger('submit');

				This.element.addClass('forms--loading');

				var $buttons=This._getButtons();
				$buttons.attr('disabled', 'disabled');

				$buttons.addClass('button--loading')

				if (typeof tinymce != 'undefined')
					tinymce.triggerSave();

				$.ajax({
					url: This.$form.attr('action'),
					cache: false,

					method: 'post',
					data: This._getVars(),

					success: function(data, text, xhr) {
						This._trigger('presuccess');
						$buttons.removeAttr('disabled');
						This.element.removeClass('forms--loading');
						$buttons.removeClass('button--loading');

						var $data=null;

						if (This.options.datahandler) {
							$.proxy(This.options.datahandler, This.$form)(data);
						} else {
							var ct=xhr.getResponseHeader('content-type');

							if (ct.indexOf('javascript')==-1) {
								$data=$(data);
								This.element.replaceWith($data);
							}
						}

						This.sending=false;
						This.action=null;

						This._trigger('success', this, $data);
					},

					error: function() {
						$buttons.removeAttr('disabled');
						This.element.removeClass('forms--loading');
						$buttons.removeClass('button--loading');

						This.sending=false;
						This.action=null;

						This._trigger('error');
					}
				});

				return false;
			} else {
				return true;
			}
		}
	});
})(jQuery);
