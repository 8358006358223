declare var FB: any;

export default class ShareUtils {
    public static shareFb(url?: string): void {
        if (url == null) {
            url = window.location.href;
        }

        FB.ui({
            method: 'share',
            href: url
        });
    }

    public static discover($parent: JQuery): void {
        $('.js-share-facebook', $parent).click((e) => {
			e.preventDefault();

			ShareUtils.shareFb();
		});
    }
}
