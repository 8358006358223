(function($) {
	$.widget('blcms.formTip', {
		options: {
			posx: 'right',
			posy: 'top',

			autoShow: true,
			pos: null,
			message: null,
			cssclass: null,
			fx: 0.9,
			fy: 0.3
		},

		$popup: null,
		attached: null,

		_create: function() {
			var This=this;

			This.$popup=$('\
				<div class="formtip">\
					<div class="formtip__wrapper">\
						<div class="formtip__close"></div>\
						<div class="formtip__body">\
							<span class="formtip__inner"></span>\
						</div>\
					</div>\
				</div>\
			');

			$('.formtip__wrapper', This.$popup).click(function(e) {
				e.stopPropagation();
			});

			$('.formtip__close', This.$popup).click(function(e) {
				e.preventDefault();

				$.proxy(This.close, This).call();
			});

			if (This.options.cssclass) {
				This.$popup.addClass(This.options.cssclass);
			}

			if (This.options.autoShow) {
				This.show();
			}
		},

		message: function(str) {
			if (str!==undefined) {
				this.options.message=str;
			} else {
				return this.options.message=str;
			}
		},

		show: function(pos) {
			var This=this;

			var $this=This.element;
			var $tip=This.$popup;

			$('.formtip__inner', $tip).html(This.options.message);

			$tip.css({
				visiblity: 'hidden',
				top: 0,
				left: 0
			});

			$tip.show();

			if (!This.attached) {
				$('body').append(This.$popup);

				This.attached=true;
			}

			var $wrapper=$('.formtip__wrapper', $tip);

			var width=$wrapper.width();
			var height=$wrapper.height();

			var docwidth=$(document).width();

			if (pos==null)
				pos=This.options.pos;

			if (pos==null) {
				var fx=This.options.fx;
				var fy=This.options.fy;

				var xx, yy;

				if (This.options.posx=='left') {
					xx=-width*fx;
				} else {
					xx=$this.outerWidth()*fx;
				}

				if (This.options.posy=='bottom') {
					yy=$this.outerHeight()+height*fy;
				} else {
					yy=$this.outerHeight()*fy;
				}

				pos={
					top: $this.offset().top+yy,
					left: $this.offset().left+xx
				};
			}

			$tip.css({
				top: pos.top,
				left: pos.left
			});

			var wrappos=$wrapper.offset();

			if (wrappos.left+width>docwidth) {
				$tip.addClass('formtip--inverted');
			} else {
				$tip.removeClass('formtip--inverted');
			}

			$tip.hide();
			$tip.css('visiblity', '');
			$tip.fadeIn(200);

			$(document).one('click.formTip, keypress.formTip', $.proxy(This.close, This));

			$('html,body').animate({
				scrollTop: $tip.offset().top-height-100
			}, 300);
		},

		close: function() {
			var This=this;

			This.$popup.fadeOut(200, function() {
				This.$popup.detach();
				This.attached=false;
			});
		}
	});
})(jQuery);