export default class QInfoBar {
    private $el: JQuery;
    private $items: JQuery;
    private $tabs: JQuery;

    private current: number;
    private timer: number;

    public constructor($el: JQuery) {
        this.$el = $el;

        this.$items = $('.qinfo__item', $el);
        this.$tabs = $('.qinfo__tab', $el);

        this.initHtml();
    }

    private show(ix: number): void {
        let $item = this.$items.eq(ix);
        let $itemsnot = this.$items.not($item);

        let $tab = this.$tabs.eq(ix);
        let $tabsnot = this.$tabs.not($tab);

        $tab.addClass('qinfo__tab--selected');
        $tabsnot.removeClass('qinfo__tab--selected');

        $item.addClass('qinfo__item--selected');
        $itemsnot.removeClass('qinfo__item--selected');

        this.current = ix;
    }

    private next(): void {
        let ix = (this.current + 1) % this.$tabs.length;

        this.show(ix);
    }

    private resetTimer(): void {
        if (this.timer != null) {
            clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
            if (!this.$el.hasClass('qinfo--hover')) {
                this.next();
            }
        }, 4000);
    }

    private initHtml(): void {
        this.show(0);

        let This = this;

        this.$tabs.click(function (e) {
            This.resetTimer();
            This.show($(this).index());
        });

        this.$el.hover(
            () => { this.$el.addClass('qinfo--hover'); },
            () => { this.resetTimer(); this.$el.removeClass('qinfo--hover'); }
        );

       this.resetTimer();

        this.$el.addClass('qinfo--inited');
    }

    public static discover($parent: JQuery): void {
        $('.qinfo', $parent).each((i, el) => {
            new QInfoBar($(el));
        });
    }

    public static initModule(): void {
    }
}
