import Form from "../../modules/core/forms/theme/js/Form";
import ObjectFit from "../../modules/utils/utils-js/theme/js/utils/ObjectFit";
import OpenInfo from "../../modules/utils/utils-js/theme/js/utils/OpenInfo";
import QInfoBar from "./bars/qinfo";
import Utils from "../../modules/utils/utils-js/theme/js/utils/Utils";
import ShareUtils from "../../modules/utils/utils-js/theme/js/utils/ShareUtils";

declare var Slider: any;

export default class Site {
	public static updateElements($parent?: JQuery): void {
		Utils.updateElements($parent);
		QInfoBar.discover($parent);
		ShareUtils.discover($parent);
	}

	private static updateFloat() {
		var $header = $('.header');

		var top = $(window).scrollTop();

		var wh = $(window).height();
		var wx = $(window).width();

		var hsize = $header.data('size');

		$('body').toggleClass('float', top > 0);
		$('body').toggleClass('nofloat', top == 0);

		$('body').toggleClass('body--float-108', top >= 108);
		$('body').toggleClass('body--float-200', top >= 200);
	}

	private static initHtml() {
		var $mainmenu=$('.mainmenu');

		$('.menubutton', $mainmenu).click(function(e) {
			e.preventDefault();

			$mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
			$('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
		});

		Site.updateElements();
	}

	private static initRwd() {
		var $header = $('.header');
		$header.addClass('header--noanim');

		$(window).resize(Site.updateFloat);
		$(window).scroll(Site.updateFloat);

		Site.updateFloat();

		$header.removeClass('header--noanim');
	}

	private static initLoad(): void {
		$(window).on('load', () => {
			$('body').addClass('body--loaded');

			setTimeout(function() {
				$('body').removeClass('body--loading');
				$('body').addClass('body--loaded-1s');
			}, 1000);
		});
	}

	public static init() {
		(<any> window).Site = Site;

		OpenInfo.initModule();
		Form.initModule();
		QInfoBar.initModule();

		$(document).ready(() => {
			Site.initHtml();
			Site.initRwd();
		});

		Site.initLoad();
	}
}
